<template>
  <footer>
    <div class="viewport">
      <div class="row d-flex justify-content-between">
        <div class="col-md-2">
          <h3><router-link to="/services">Services</router-link></h3>
          <ul class="list-unstyled">
            <li><router-link to="/services/direct-hiring">Direct hiring</router-link></li>
            <li>
              <router-link to="/services/dedicated-nearshore-team"
                >Dedicated Nearshore Team</router-link
              >
            </li>
            <li>
              <router-link to="/services/freelancing-network">Freelancing Network</router-link>
            </li>
          </ul>
        </div>
        <div class="col-md-2">
          <h3><router-link to="/expertise">Expertise</router-link></h3>
          <ul class="list-unstyled">
            <li><router-link to="/expertise">Software Development</router-link></li>
            <li><router-link to="/expertise">UX/UI Design</router-link></li>
            <li><router-link to="/expertise">QA</router-link></li>
            <li><router-link to="/expertise">Technical Writing</router-link></li>
            <li><router-link to="/expertise">Technical Ops</router-link></li>
            <li><router-link to="/expertise">Technical Support</router-link></li>
            <li><router-link to="/expertise">Technical Management</router-link></li>
          </ul>
        </div>
        <div class="col-md-2">
          <h3><router-link to="/careers">Careers</router-link></h3>
          <ul class="list-unstyled">
            <li><router-link to="/relocate-to-sweden">Relocating to Sweden</router-link></li>
            <li>
              <router-link to="/services/freelancing-network"
                >Join our Freelancer Network</router-link
              >
            </li>
            <li><router-link to="/submit-cv">Submit your CV</router-link></li>
          </ul>
        </div>
        <div class="col-md-2">
          <h3><router-link to="/about-us">About Us</router-link></h3>
          <ul class="list-unstyled">
            <li>
              <router-link to="/corporate-social-responsibility"
                >Corporate Social Responsibility</router-link
              >
            </li>
          </ul>
        </div>
        <div class="col-md-3">
          <h3><router-link to="/contact-us">Contact Us</router-link></h3>
          <ul class="list-unstyled">
            <li>Phone SE: +46 767 78 09 62</li>
            <li>Phone BG: +359 8 798 28 500</li>
            <li>
              <a href="https://www.facebook.com/comstream" target="_blank"
                ><i class="fa-brands fa-facebook-square"></i
              ></a>
              <a href="https://www.linkedin.com/company/comstream" target="_blank"
                ><i class="fa-brands fa-linkedin"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="trademark">
      <div class="viewport d-flex">
        © {{ currentYear }} Comstream AB. All rights reserved.
        <router-link class="ms-auto" to="/privacy-policy">Privacy Policy</router-link>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>
<style lang="scss" scoped>
footer {
  background: #000;
  padding: 30px 0 0 0;
  h3 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
    a {
      color: #fff;
      text-decoration: none;
    }
  }
  .list-unstyled {
    li {
      margin-bottom: 8px;
      color: rgba(255, 255, 255, 0.6);
      a {
        color: rgba(255, 255, 255, 0.6);
        font-size: 16px;
        text-decoration: none;
        i {
          color: #fff;
          margin-right: 20px;
          font-size: 21px;
        }
      }
    }
  }
  .trademark {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    width: 100%;
    padding: 12px 0;
    color: rgba(255, 255, 255, 0.54);
    font-size: 14px;
    a {
      text-decoration: none;
      color: rgba(255, 255, 255, 0.54);
    }
  }
}
</style>
