<template>
  <div class="privacy">
    <div
      class="page-header d-flex align-items-center justify-content-center"
      style="background: url(img/homepage/header_home.jpg); background-size: cover"
    >
      <div class="viewport">
        <h1>Privacy Policy</h1>
        <p>
          This is Comstream’s Privacy Policy for comstream.eu and for the services that are provided
          through this site by Comstream AB.
        </p>
      </div>
      <div class="overlay"></div>
    </div>
    <div class="viewport">
      <div class="content">
        <h2>Personal data that we collect</h2>
        <p>
          Comstream collects data to make sure our services function effectively and that we can
          give you the best possible experience.
        </p>

        <p>
          We receive part of the information by registering how you interact with our Services, for
          example by using web technology like cookies on our sites.
        </p>
        <p>
          When we treat your personal data, we do this with your consent and/or based on legitimate
          interests and needs. Which data we collect depends on the context of your interactions
          with Comstream, your choices, including your integrity settings, and the services and
          functions you use. The data we collect can include the following:
        </p>
        <ul class="list-unstyled">
          <li>
            <strong>Name and contact details.</strong> We collect your first and last name, email
            address, job title, company, number of employees at the company, and other similar
            contact details.
          </li>
          <li>
            <strong>Demographic data</strong> We collect data about you, such as age, sex, country
            and language setting.
          </li>
          <li>
            <strong>Device and user data</strong> We collect data about your device and how you and
            your device interact with Comstream and our services. For example, we collect
            information about which pages you visit and which guides you download. We also collect
            data about the device, connection and configuration, which means data about your device
            and the network you use to connect to our Services. This can also include information
            about operative system and IP address.
          </li>
          <li>
            <strong>Interests.</strong> We collect data about your interests, for example, which
            kind of content you are most interested in. On top of the interests that you expressly
            tell us, your interests can also be derived or concluded from other information we
            collect.
          </li>
          <li>
            We also collect information that you provide to us and the content in messages that you
            send to us, such as information provided in our contact form or feedback that you write,
            or questions and information you submit to support.
          </li>
        </ul>
        <h2>How we use your personal data</h2>
        <p>
          We use information that we collect for the following main reasons, which are described in
          more detail below:
        </p>
        <ul class="list-unstyled">
          <li>
            To operate our business and provide (including improving and adjust) the services we
            offer
          </li>
          <li>To fulfill our commitment as a client, supplier and employer</li>
          <li>
            To market our services and show advertisements, on our own sites and on platforms where
            we have permission from you or where it is allowed according to law.
          </li>
        </ul>
        <p>
          Due to these reasons, we combine the data we collect to give you a smoother, more coherent
          and personally-tailored experience. We are using the gathered data for these purposes:
        </p>
        <ul class="list-unstyled">
          <li>
            <strong>Provide and improve our products and services.</strong> We use data to provide
            and improve the services we offer and to perform necessary business operations. This
            includes managing the services, maintaining and improving the services, developing new
            functions and offers.
          </li>
          <li>
            <strong>Communication.</strong> We use data to stay in touch with you. For example, we
            can contact you by to answer your questions about our offers and services.
          </li>
          <li>
            <strong>Advertisements.</strong> We use the data we collect through our interactions
            with you on our home page, emails and third party websites, to show advertisements.
          </li>
        </ul>
        <h2>Cookies and technology</h2>
        <p>
          Comstream uses cookies to improve services and the user experience on our website and to
          perform necessary business operations, as well as provide statistics on the usage of the
          home page or service.
        </p>
        <p>
          If you visit a homepage, a small text file that contains text information, a so-called
          cookie, gets stored on the visitor’s computer. According to the Electronic Communications
          Act, which entered into force on July 1, 2011, anyone visiting web pages should be
          informed about what cookies are used for and agree to their usage.
        </p>
        <p>
          You can decide yourself if you accept cookies or not. This can be set in your web browser.
        </p>

        <h2>Contact us</h2>
        <p>
          If you have questions about your personal data, a complaint or a question to our Data
          Protection Representative, please contact us via the
          <a href="https://web.archive.org/web/20210622233342/https://comstream.eu/contact-us"
            >contact form.</a
          >
          We will answer your query promptly.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import titleMixin from '../mixins/titleMixin';
export default {
  mixins: [titleMixin],
  title: 'Privacy Policy | Comstream',
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  },
};
</script>

<style lang="scss" scoped>
.privacy {
  .content {
    padding: 70px 0;
  }
}
</style>
